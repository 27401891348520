import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../common/common.service';
import { ConstantService } from '../common/constant.service';
import { DataService } from '../common/data.service';
import { NotificationService } from '../common/notification.service';
import { UploadService } from '../common/upload.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  showFrontEnd = false;
  showApis = false;
  showBackend = false;
  showMessage = false;
  showDatabase = false;
  showPlateForm = false;

  isCVDownloadEnable = false;
  aboutMe = '';
  imageUrl = '';
  cvURL = '';

  education = [];
  experiences = [];
  skillMap = new Map();

  isBurgerClicked = false;

  colors = ['#1ebbd7', '#424983', '#df919c', '#fccab4', '#e0f5d0', '#70cdcc', '#9beb34', '#423561', '#ff8ab3', '#ff5791', '#ff8eb5', '#ff8e9a', '#fed8d8', '#ee11ad', '#f603a3', '#8e7cc3', '#fefdfc', '#1a0e06', '#5b3216', '#5dfdad', '#168b64', '#d9b8c4', '#96516a', '#f8ac6a', '#d94c12', '#91a781', '#3e3530', '#88a174', '#ffccdd', '#44dd66', '#2b0c12', '#602ac3', '#5743bb', '#949639', '#babc48', '#88a174', '#e69138', '#ffa500', '#66334f', '#cbd9db', '#0c343d', '#80576b'];

  constructor(private router: Router, private dataService: DataService, private notification: NotificationService, private spinner: NgxSpinnerService, private uploadService: UploadService, public constant: ConstantService, private commonService: CommonService) { }

  ngOnInit(): void {
    this.commonService.logEvents("About");
    let doc = document.getElementsByClassName("pg-canvas")[0];
    if (!this.commonService.isEmptyObject(doc)) {
      doc.setAttribute('style', 'opacity: 0.2; position: fixed !important; height: 100%;');
    }

    this.fetchAboutMe();
    this.fetchCVData();

    this.fetchEduEmp(this.constant.EXPERIENCE);
    this.fetchEduEmp(this.constant.EDUCATION);

    this.fetchSkill(this.constant.FRONTEND);
    this.fetchSkill(this.constant.APIS);
    this.fetchSkill(this.constant.BACKEND);
    this.fetchSkill(this.constant.MESSAGING);
    this.fetchSkill(this.constant.DATABASE);
    this.fetchSkill(this.constant.DEVOPS);
  }

  redirectToLogin() {
    this.router.navigate(['/login-admin']);
  }

  hideAll() {
    this.showFrontEnd = false;
    this.showApis = false;
    this.showBackend = false;
    this.showMessage = false;
    this.showDatabase = false;
    this.showPlateForm = false;
  }


  clickedInsideBurger() {
    this.isBurgerClicked = true;
  }

  fetchAboutMe() {
    this.spinner.show();
    const ref = this.dataService.dataColRef(this.constant.ABOUT).ref;
    ref.onSnapshot(snapshot => {
      snapshot.docs.forEach(doc => {
        if (doc.data()) {
          this.aboutMe = doc.data().aboutMe;
          this.imageUrl = doc.data().imageUrl;
        }
      });
      this.spinner.hide();
    });

  }

  fetchCVData() {
    this.spinner.show();
    const ref = this.dataService.dataColRef(this.constant.CV_DATA).ref;
    ref.onSnapshot(snapshot => {
      snapshot.docs.forEach(doc => {
        if (doc.data()) {
          this.isCVDownloadEnable = doc.data().cvEnable;
          this.cvURL = doc.data().imageUrl;
        }
      });
    });
    this.spinner.hide();
  }


  fetchEduEmp(type) {
    this.spinner.show();
    const ref = this.dataService.dataColRef(type).ref;
    ref.onSnapshot(snapshot => {
      if (type === this.constant.EDUCATION) {
        this.education = [];
      } else if (type === this.constant.EXPERIENCE) {
        this.experiences = [];
      }
      snapshot.docs.forEach(doc => {
        if (doc.data()) {
          if (type === this.constant.EDUCATION) {
            this.education.push({ key: doc.id, sequence: doc.data().sequence, role: doc.data().role, period: doc.data().period, location: doc.data().location, description: doc.data().description })
          } else if (type === this.constant.EXPERIENCE) {
            this.experiences.push({ key: doc.id, sequence: doc.data().sequence, role: doc.data().role, period: doc.data().period, location: doc.data().location, description: doc.data().description })
          }
        }
      });
      if (type === this.constant.EDUCATION) {
        this.commonService.sortList(this.education);
      } else if (type === this.constant.EXPERIENCE) {
        this.commonService.sortList(this.experiences);
      }
      this.spinner.hide();
    });
  }


  fetchSkill(type) {
    this.spinner.show();
    const ref = this.dataService.skillColRef(type).ref;
    ref.onSnapshot(snapshot => {
      let skills = [];
      snapshot.docs.forEach(doc => {
        if (doc.data()) {
          skills.push({ key: doc.id, sequence: doc.data().sequence, skill: doc.data().skill, percentage: doc.data().percentage })
        }
      });
      this.commonService.sortList(skills);
      this.generateCSS(skills);
      this.skillMap.set(type, skills);
      this.spinner.hide();
    });
  }

  generateCSS(skills) {
    let dynamicCSS = '';
    skills.forEach(skl =>
      dynamicCSS += '#Skill-' + skl.percentage + ' {\n' +
      '    width: ' + skl.percentage + '%;\n' +
      '    animation: Animate-' + skl.percentage + ' 4s;\n' +
      '    -webkit-animation: Animate-' + skl.percentage + ' 4s;\n' +
      '    -moz-animation: Animate-' + skl.percentage + ' 4s;\n' +
      '    -o-animation: Animate-' + skl.percentage + ' 4s;\n' +
      '    height: 25px;\n' +
      '    position: absolute;\n' +
      '    background-color: ' + this.colors[this.getRandomInt()] + ';\n' +
      '}\n' +
      '\n' +
      '@keyframes Animate-' + skl.percentage + ' {\n' +
      '    from {\n' +
      '        width:  10%;\n' +
      '    }\n' +
      '    to {\n' +
      '        width: ' + skl.percentage + '%\n' +
      '    }\n' +
      '}\n' +
      '\n' +
      '@-webkit-keyframes Animate-' + skl.percentage + ' {\n' +
      '    from {\n' +
      '        width:  10%;\n' +
      '    }\n' +
      '    to {\n' +
      '        width: ' + skl.percentage + '%\n' +
      '    }\n' +
      '}\n' +
      '\n' +
      '@-moz-keyframes Animate-' + skl.percentage + ' {\n' +
      '    from {\n' +
      '        width:  10%;\n' +
      '    }\n' +
      '    to {\n' +
      '        width: ' + skl.percentage + '%\n' +
      '    }\n' +
      '}\n' +
      '\n' +
      '@-o-keyframes Animate-' + skl.percentage + ' {\n' +
      '    from {\n' +
      '        width:  10%;\n' +
      '    }\n' +
      '    to {\n' +
      '        width: ' + skl.percentage + '%\n' +
      '    }\n' +
      '}'
    );


    const node = document.createElement('style');
    node.innerHTML = dynamicCSS
    document.body.appendChild(node);
  }


  getRandomInt(min = 0, max = 40) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
