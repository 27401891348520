import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/pages/common/data.service';
import { CommonService } from '../../common/common.service';
import { NotificationService } from '../../common/notification.service';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  username: string = '';
  password: string = '';

  u: string = '';
  p: string = '';
  a: boolean = false;

  constructor(private dataService: DataService, private router: Router, private loginService: LoginService, private notification: NotificationService, private spinner: NgxSpinnerService, private commonService: CommonService) { }

  ngOnInit(): void {
    let doc = document.getElementsByClassName("pg-canvas")[0];
    if (!this.commonService.isEmptyObject(doc)) {
      doc.setAttribute('style', 'opacity: 0.2; position: fixed !important; height: 100%;');
    }
    const loginRef = this.dataService.getLoginRef();
    loginRef.snapshotChanges().subscribe(data => {
      if (data.payload.data()) {
        this.u = data.payload.data().username;
        this.p = data.payload.data().password;
        this.a = data.payload.data().isAllowLogin;
      }
    });
  }


  login() {
    this.spinner.show();
    if (this.a && this.username === this.u && this.password === this.p) {
      this.loginService.setLoginStatus(true);
      this.router.navigate(['/admin-area/maintenance']);
      this.notification.displayNotification("Successfully Login.");
    } else {
      this.notification.displayNotification("Login Failed.")
    }
    this.spinner.hide();
  }


  resetForm() {
    this.username = '';
    this.password = '';
  }

  isFilled() {
    if (this.username.length > 0 && this.password.length > 0) {
      return false;
    } else {
      return true;
    }
  }



}
