import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../common/common.service';
import { ConstantService } from '../common/constant.service';
import { DataService } from '../common/data.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  services = [];
  showService: any = [];

  fallBackImage = 'assets/img/service.png';

  constructor(private dataService: DataService, private constant: ConstantService, private commonService: CommonService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.commonService.logEvents("Services");
    let doc = document.getElementsByClassName("pg-canvas")[0];
    if (!this.commonService.isEmptyObject(doc)) {
      doc.setAttribute('style', 'opacity: 0.2; position: fixed !important; height: 100%;');
    }
    this.showService = JSON.parse(localStorage.getItem(this.constant.SERVICE));
    if (this.commonService.isEmptyObject(this.showService)) {
      this.getServiceList();
    }
  }


  getServiceList() {
    this.spinner.show();
    const serviceRef = this.dataService.dataColRef(this.constant.SERVICE).ref;
    serviceRef.onSnapshot(snapshot => {
      this.services = [];
      snapshot.docs.forEach(doc => {
        if (doc.data()) {
          this.services.push({ key: doc.id, sequence: doc.data().sequence, imageUrl: doc.data().imageUrl, name: doc.data().name, description: doc.data().description });
        }
      });
      this.commonService.sortList(this.services);
      this.renderService();
      this.spinner.hide();
    });
  }

  renderService() {
    let y = 0
    let row = [];
    let col = [];
    for (let serv of this.services) {
      col.push(serv);
      y++;
      if (y == 3) {
        row.push(col);
        col = [];
        y = 0;
      }
    }

    let size = 3 - this.services.length % 3;
    if (size != 3) {
      for (let i = 0; i < size; i++) {
        col.push([]);
      }
      row.push(col);
    }

    this.showService = row;
    localStorage.setItem(this.constant.SERVICE, JSON.stringify(row));
  }

}
