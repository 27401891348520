import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './pages/theme/header/header.component';
import { FooterComponent } from './pages/theme/footer/footer.component';
import { LoginComponent } from './pages/security/login/login.component';
import { RouterConfigService } from './pages/common/router-config.service';
import { ConstantService } from './pages/common/constant.service';
import { AuthGuardService } from './pages/security/login/auth-guard.service';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';
import { ServicesComponent } from './pages/services/services.component';
import { FormsModule } from '@angular/forms';
import { DataService } from './pages/common/data.service';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/firestore';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AdminAreaComponent } from './pages/theme/adminarea/adminarea.component';
import { NotificationService } from './pages/common/notification.service';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonService } from './pages/common/common.service';
import { ImgFallbackModule } from 'ngx-img-fallback';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AdminAreaComponent,
    ContactComponent,
    AboutComponent,
    ServicesComponent,
    PortfolioComponent,
    HomepageComponent,
    LoginComponent
  ],
  exports: [HomepageComponent],
  imports: [
    BrowserModule, BrowserAnimationsModule,
    AppRoutingModule, FormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule, HttpClientModule, AngularFireDatabaseModule, AngularFireStorageModule, NgxSpinnerModule, ToastModule, ImgFallbackModule, AngularFireAnalyticsModule

  ],
  providers: [RouterConfigService, ConstantService, AuthGuardService, DataService, AngularFirestore, NotificationService, NgxSpinnerService, MessageService, CommonService],
  bootstrap: [AppComponent]
})
export class AppModule { }
