import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import * as firebase from 'firebase';
import { NgxSpinnerService } from 'ngx-spinner';

import { Subject, Observable } from 'rxjs';
import { DataService } from './data.service';
import { NotificationService } from './notification.service';
import { finalize } from 'rxjs/operators';
import { FileUpload } from '../admin/maintenance/FileUpload';
import { ConstantService } from './constant.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  private basePath = '/uploads';

  updateHit = 0;

  constructor(private db: AngularFireDatabase, private storage: AngularFireStorage, private dataService: DataService,
    private notification: NotificationService, private spinner: NgxSpinnerService, private constant: ConstantService) { }


  pushFileToStorage(location: any, fileUpload: FileUpload, dataObject: any, isNew: boolean): Observable<number> {
    this.spinner.show();
    const filePath = `${location}/${dataObject.key}/${fileUpload.file.name}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, fileUpload.file);
    uploadTask.snapshotChanges().pipe(
      finalize(() => {
        storageRef.getDownloadURL().subscribe(downloadURL => {
          fileUpload.url = downloadURL;
          fileUpload.name = fileUpload.file.name;
          dataObject.imageUrl = downloadURL;
          isNew ? this.saveFileData(location, fileUpload, dataObject) : this.updateFileData(location, fileUpload, dataObject);
        });
      })
    ).subscribe();

    return uploadTask.percentageChanges();
  }


  private saveFileData(location: any, fileUpload: FileUpload, dataObject: any) {

    const ref = this.dataService.dataRef(location, dataObject.key);

    const map = new Map();
    map.set('key', dataObject.key);
    map.set('name', dataObject.name);
    map.set('description', dataObject.description);
    map.set('imageUrl', fileUpload.url);
    map.set('sequence', dataObject.sequence);

    if (location === this.constant.PORTFOLIO) {
      map.set('url', dataObject.url);
    }
    ref.set(dataObject, {
      merge: true
    }).then((result) => {
      this.spinner.hide();
    }).catch((error) => {
      this.spinner.hide();
    });
  }


  private updateFileData(location: any, fileUpload: FileUpload, objectToSave: any) {
    const ref = this.dataService.dataRef(location, objectToSave.key);
    let data =
      location === this.constant.SERVICE ? {
        key: objectToSave.key,
        name: objectToSave.name,
        description: objectToSave.description,
        imageUrl: fileUpload.url,
        sequence: objectToSave.sequence
      } : {
        key: objectToSave.key,
        name: objectToSave.name,
        description: objectToSave.description,
        imageUrl: fileUpload.url,
        url: objectToSave.url,
        sequence: objectToSave.sequence
      };
    ref.set(objectToSave, {
      merge: true
    }).then((result) => {
      this.notification.displayNotification('Successfully Updated ' + location);
      this.spinner.hide();
    }).catch((error) => {
      this.spinner.hide();
      this.notification.displayNotification('Failed To Update ' + location);
    });

  }

  convertMapToObj(map) {
    const obj = Object.create(null);
    for (const [k, v] of map) {
      obj[k] = v;
    }
    return obj;
  }

  deleteFileUpload(fileUpload: FileUpload) {
    this.deleteFileDatabase(fileUpload.key)
      .then(() => {
        // this.deleteFileStorage(fileUpload.name);
      })
      .catch(error => this.spinner.hide());
  }

  private deleteFileDatabase(key: string) {
    return this.db.list(this.basePath).remove(key);
  }

  deleteFileStorage(location: any, key: any) {
    const path = `${location}/${key}`;
    this.deleteFolderContents(path);

  }

  deleteFolderContents(path) {
    this.spinner.hide();
    const ref = firebase.storage().ref(path);
    ref.listAll()
      .then(dir => {
        dir.items.forEach(fileRef => {
          this.deleteFile(ref.fullPath, fileRef.name);
        });
        dir.prefixes.forEach(folderRef => {
          this.deleteFolderContents(folderRef.fullPath);

        });
      })
      .catch(error => {
        this.spinner.hide();
      });
  }

  deleteFile(pathToFile, fileName) {
    const ref = firebase.storage().ref(pathToFile);
    const childRef = ref.child(fileName);
    childRef.delete();
  }
}

