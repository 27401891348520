import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../common/common.service';
import { ConstantService } from '../common/constant.service';
import { DataService } from '../common/data.service';
declare var particleGround: any;

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  showService: any = [];
  dataObjects = [];
  showPortfolio: any = [];


  constructor(private router: Router, private constant: ConstantService, private dataService: DataService, private commonService: CommonService) {

  }

  ngOnInit(): void {
    this.commonService.logEvents("HomePage");
    let doc = document.getElementsByClassName("pg-canvas")[0];
    if (!this.commonService.isEmptyObject(doc)) {
      doc.setAttribute('style', 'opacity: 0.5; position: fixed !important; height: 100%;');
    }
    $("p span").hover(function () {
      $(this).animate({ fontSize: "7vw" }, 300)
    }, function () {
      $(this).animate({ fontSize: "5vw" }, 300)
    });
    this.getPortfolioList();
    this.getServiceList();
  }


  redirectToLogin() {
    this.router.navigate(['/login-admin']);
  }

  getPortfolioList() {
    const portfolioRef = this.dataService.dataColRef(this.constant.PORTFOLIO).ref;
    portfolioRef.onSnapshot(snapshot => {
      this.dataObjects = [];
      snapshot.docs.forEach(doc => {
        if (doc.data()) {
          this.dataObjects.push({ key: doc.id, sequence: doc.data().sequence, imageUrl: doc.data().imageUrl, name: doc.data().name, description: doc.data().description, url: doc.data().url });
        }
      });
      this.commonService.sortList(this.dataObjects);
      this.renderComponent(this.constant.PORTFOLIO, this.dataObjects);
    });
  }

  getServiceList() {
    const serviceRef = this.dataService.dataColRef(this.constant.SERVICE).ref;
    serviceRef.onSnapshot(snapshot => {
      this.dataObjects = [];
      snapshot.docs.forEach(doc => {
        if (doc.data()) {
          this.dataObjects.push({ key: doc.id, sequence: doc.data().sequence, imageUrl: doc.data().imageUrl, name: doc.data().name, description: doc.data().description });
        }
      });
      this.commonService.sortList(this.dataObjects);
      this.renderComponent(this.constant.SERVICE, this.dataObjects);
    });
  }

  renderComponent(location, dataObject) {
    let y = 0
    let row = [];
    let col = [];
    for (let obj of dataObject) {
      col.push(obj);
      y++;
      if (y == 3) {
        row.push(col);
        col = [];
        y = 0;
      }
    }

    let size = 3 - dataObject.length % 3;
    if (size != 3) {
      for (let i = 0; i < size; i++) {
        col.push([]);
      }
      row.push(col);
    }
    localStorage.setItem(location, JSON.stringify(row));
  }


}
