import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  loginCredentials: any;
  loginStatus: any;

  constructor() { }


  setLoginCredentials(userName, userPassword) {
    this.loginCredentials = { username: userName, password: userPassword };
  }

  getLoginCredentials() {
    return this.loginCredentials;
  }


  setLoginStatus(status) {
    this.loginStatus = status;
  }


  getLoginStatus() {
    return this.loginStatus;
  }


}
