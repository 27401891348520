import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../common/common.service';
import { ConstantService } from '../common/constant.service';
import { DataService } from '../common/data.service';
import { NotificationService } from '../common/notification.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  name: string = '';
  emailId: string = '';
  contact: string = '';
  message: string = '';

  constructor(private dataService: DataService, private notification: NotificationService, private spinner: NgxSpinnerService, public constant: ConstantService, private commonService: CommonService) { }

  ngOnInit(): void {
    this.commonService.logEvents("Contact");
    let doc = document.getElementsByClassName("pg-canvas")[0];
    doc.setAttribute('style', 'opacity: 0.1; position: fixed !important; height: 100%;');
  }


  resetForm() {
    this.name = '';
    this.emailId = '';
    this.contact = '';
    this.message = '';
  }

  isFilled() {
    if (this.name.length > 0 && this.emailId.length > 0 && this.contact.length > 0 && this.message.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  validation() {
    let validate = false;
    if (this.emailId) {
      let emailPattern = "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$";
      this.emailId.match(emailPattern);
    }
    if (this.contact) {
      let contactPattern = " /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im";
      this.contact.match(contactPattern);
    }
  }


  sendMessage() {
    this.spinner.show();
    const contactRef = this.dataService.contactListRef();
    let data =
    {
      name: this.name,
      emailId: this.emailId,
      contact: this.contact,
      message: this.message
    };
    contactRef.set(data, {
      merge: true
    }).then(() => {
      this.resetForm();
      this.notification.displayNotification("Message Sent. Thanks for Contacting. We will get back soon.");
      this.spinner.hide();
    }).catch((error) => {
      this.notification.displayNotification("Failed To Send Message. Please Try Again Later.");
      this.spinner.hide();
    });

  }

}
