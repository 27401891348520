export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyCU1X2Umr6kXAQ0ja6Z8S4-AohVtHVzta8",
    authDomain: "dream-innovator-fe12b.firebaseapp.com",
    databaseURL: "https://dream-innovator-fe12b-default-rtdb.firebaseio.com",
    projectId: "dream-innovator-fe12b",
    storageBucket: "dream-innovator-fe12b.appspot.com",
    messagingSenderId: "273980026063",
    appId: "1:273980026063:web:92f526bfc044c05406dbba",
    measurementId: "G-3Z68ZYEHC3"
  }
};
