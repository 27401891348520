import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { LoginService } from "../login.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {


  constructor(private router: Router, private loginService: LoginService) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    var isAuthenticated = this.loginService.getLoginStatus();
    if (!isAuthenticated) {
      this.router.navigate(['/login-admin']);
    }
    return isAuthenticated;
  }

}
