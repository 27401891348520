import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/analytics';
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  analytics = null;

  constructor() {
    this.analytics = firebase.analytics();
  }

  sortList(objs) {
    objs.sort((a, b) => (a.sequence > b.sequence) ? 1 : ((b.sequence > a.sequence) ? -1 : 0));
  }


  isEmptyObject(obj: any) {
    if (obj === undefined || obj === null || obj.length === 0) {
      return true;
    }
    return false;
  }


  logEvents(eventName: String): void {
    this.analytics.logEvent(eventName);
  }

}
