import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConstantService } from '../../common/constant.service';
import { DataService } from '../../common/data.service';
import { LoginService } from '../../security/login.service';
import * as $ from 'jquery';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  menuOption = {
    showPortfolio: false,
    showServices: false,
    showContact: false,
    showAbout: false
  }

  constructor(public loginService: LoginService, private spinner: NgxSpinnerService, public constant: ConstantService, private dataService: DataService) { }

  ngOnInit(): void {
    this.getSetting();
    $('.nav-link').on('click', function () {
      $('.navbar-collapse').collapse('hide');
    });
  }

  logout() {
    this.loginService.setLoginStatus(false);
    this.loginService.setLoginCredentials(null, null);
  }

  getSetting() {
    this.spinner.show();
    const ref = this.dataService.dataColRef(this.constant.SETTINGS).ref;
    ref.onSnapshot(snapshot => {
      snapshot.docs.forEach(doc => {
        if (doc.data()) {
          this.menuOption.showPortfolio = doc.data().showPortfolio;
          this.menuOption.showServices = doc.data().showServices;
          this.menuOption.showContact = doc.data().showContact;
          this.menuOption.showAbout = doc.data().showAbout;
        }
      });
      this.spinner.hide();
    });
  }

}
