import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private messageService: MessageService) { }

  public displayNotification(message: any) {
    this.messageService.add({
      key: 'custom', severity: 'info', life: 2000, summary: 'Dream Innovator: ', detail: message
    });
  }

}
